.blog-wrap {
  display: flex;
  @media(max-width:960px) {
    display: block;
  }
  .blog-items {
    flex: 1;
    ul {
      li {
        margin-top:45px;
        padding-top:45px;
        border-top:5px #FCF4E0 solid;
        &:nth-child(1) {
          margin-top:0;
          padding-top:0;
          border-top:0;
        }
        a.img-wrap {
          vertical-align: top;
          display: inline-block;
          width:221px;
          margin-right:29px;
          @media(max-width:650px) {
            width:100%;
            margin-right:0;
            margin-bottom:25px;
          }
          img {
            @media(max-width:650px) {
              width:100%;
            }
          }
        }
        .txt-wrap {
          display: inline-block;
          vertical-align: top;
          width:calc(100% - 250px);
          @media(max-width:650px) {
            width:100%;
          }
          h3 {
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0;
            line-height: 1.4em;
            @media(max-width:900px) { font-size:23px; line-height: 1.4em; }
            @media(max-width:600px) { font-size:20px; line-height: 1.4em; }
            @media(max-width:450px) { font-size:18px; line-height: 1.4em; }
            a { color: #333333; }
          }
          span.date {
            font-family:$second-font-family;
            font-size: 16px;
            color: #D0B15C;
            letter-spacing: 0;
            line-height: 1.9em;
            display: block;
            margin:0 0 10px;
            @media(max-width:900px) { font-size:15px; line-height: 1.9em; }
            @media(max-width:600px) { font-size:14px; line-height: 1.9em; }
            @media(max-width:450px) { font-size:13px; line-height: 1.9em; }

          }
          .summary {
            color: #656363;
            font-family:$second-font-family;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 1.9em;
            @media(max-width:900px) { font-size:15px; line-height: 1.9em; }
            @media(max-width:600px) { font-size:14px; line-height: 1.9em; }
            @media(max-width:450px) { font-size:13px; line-height: 1.9em; }
          }
        }
      }
    }
  }
  .blog-content {
    flex: 1;
    font-size:16px;
    font-family:$second-font-family;
    font-style: normal;
    font-weight: 400;
    line-height: 1.9em;
    @media(max-width:900px) { font-size:15px; line-height: 1.9em; }
    @media(max-width:600px) { font-size:14px; line-height: 1.9em; }
    @media(max-width:450px) { font-size:13px; line-height: 1.9em; }
    h1 {
      text-transform: uppercase;
      color:#333;
      font-size:32px;
      @media(max-width:900px) { font-size:29px; }
      @media(max-width:600px) { font-size:26px; }
      @media(max-width:450px) { font-size:24px; }
    }
    div {
      p {
        margin:0 0 1.5em;
        &:first-child {
          font-size:19px;
          line-height: 1.9em;
          @media(max-width:900px) { font-size:17px; line-height: 1.9em; }
          @media(max-width:600px) { font-size:16px; line-height: 1.9em; }
          @media(max-width:450px) { font-size:15px; line-height: 1.9em; }
        }
      }
    }
  }

}
