.inner-wrap {
  max-width:$container + px;
  width:90%;
  margin:auto;
}


.subpage-wrap {
  padding:100px 0;
  @media(max-width:900px) {  padding: 70px 0; }
  h1 {
    font-size: 42px;
    color: #171717;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    span {
      color:#CDBD69;
    }
    @media(max-width:900px) { font-size:37px; padding: 0; }
    @media(max-width:600px) { font-size:34px; padding: 0; }
    @media(max-width:450px) { font-size:30px; padding: 0; }
  }

  .main-col-txt {
    display: inline-block;
    width:calc(100% - 410px);
    vertical-align: top;
    @media(max-width:960px) {
      width:100%;
    }
    h2 {
      margin:45px 0 15px;
      font-size: 28px;
      color: #333333;
      line-height: 40px;
      @media(max-width:900px) { font-size:24px; }
      @media(max-width:600px) { font-size:21px; }
      @media(max-width:450px) { font-size:19px; }
    }
    h3 {
      color: #333333;
      margin:30px 0 25px;
      font-size: 20px;
    }
    .page-title-wrap {
      margin-bottom: 40px;
      h2 {
        font-size:22px;
        color:#181818;
        margin:0;
        line-height: 1em;
        text-transform: uppercase;
      }
      h1 {
        font-size:39px;
        color:#CDBD69;
        margin:0;
        line-height: 1.3em;
        padding: 0;
      }
    }
    blockquote {
      border-left: 3px solid #CDBD69;
      padding: 15px 30px 15px 30px;
      color: #393838;
      margin: 0 0 1.5em;
      font-weight: 600;
      margin: 60px 0;
      background: #FFF8E6;
      h2{
        margin: 15px 0;
      }
    }
    blockquote.secondquote {
      border-left: 3px solid #CDBD69;
      padding: 15px 30px 15px 30px;
      color: #fff;
      margin: 0 0 1.5em;
      font-weight: 600;
      margin: 60px 0;
      background: #1A1A1A;
      h2{
        color: #CDBD69;
      }
    }
    ul {
      margin: 0 0 30px 16px;

      li {
        list-style: disc;
        padding: 6px 20px;
        a {
          font-weight: 600;
          color: #000;
        }
      }

  }
  }
}

aside.nav {
  width:330px;
  margin-left:80px;
  display: inline-block;
  vertical-align: top;

  @media(max-width:960px) {
    margin-left:auto;
    margin-right:auto;
    margin-top: 50px;
    display: block;

    width:100%;
  }
  h3 {
    font-size: 23px;
    color: #181818;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    margin:0 0 20px;
    @media(max-width:900px) { font-size:20px; }
    @media(max-width:600px) { font-size:18px; }
    @media(max-width:450px) { font-size:17px; }
  }
  .submenu {
    background:#EEEDED;
    margin-bottom:50px;
    padding:30px;
    border-top:10px solid #CEB15A;
    ul {
      font-family:$second-font-family;
      font-size: 17px;
      color: #7F7F7F;
      margin:0;
      padding:0;
      list-style: none;
      font-style: normal;
      font-weight: 500;
      @media(max-width:900px) { font-size:16px; }
      @media(max-width:600px) { font-size:15px; }
      @media(max-width:450px) { font-size:14px; }
      li {
        margin:0;
        padding:0;
        display: block;
        border-top:1px #DBDBDB solid;
        position: relative;
        &:after {
          font-family: FontAwesome;
          content: "\f105";
          color:#7F7F7F;
          position: absolute;
          top:10px;
          right:0;
          font-size:24px;
          @media(max-width:900px) { font-size:23px; }
          @media(max-width:600px) { font-size:22px; }
          @media(max-width:450px) { font-size:21px; }
        }
        a {
          color: #7F7F7F;
          display: block;
          padding:13px 0;
          &.active {
            color:#CEB15A;
          }
        }
      }
    }
  }
  .downloadcoaching{
    background:#EEEDED;
    margin-bottom:50px;
    padding:30px 30px 0 30px;
    border-top:10px solid #CEB15A;
    width: 100%;
    display: inline-block;
    h3{
      width: 55%;
      float: left;
      font-size: 16px;
    }
    img{
      float: right;
    }
  }
  .social {
    padding:30px;
    margin-bottom: 40px;
    border-top:10px solid #CEB15A;
    background-color:#1A1A1A;
    h3 {
      color:#D0B15C;
    }
    ul {
      display: block;
      li {
        display: block;
        margin-top:10px;
        a {
          background: rgba(#FFFFFF, 0.1);
          color:#E7D5A2;
          display: block;
          font-family:$second-font-family;
          font-size:17px;
          font-style: normal;
          font-weight: 500;
          padding:10px 18px;
          position: relative;
          @media(max-width:900px) { font-size:16px; }
          @media(max-width:600px) { font-size:15px; }
          @media(max-width:450px) { font-size:14px; }
          i {
            width:50px;
            text-align:center;
            position: absolute;
            top:10px;
            right:0;
            font-size:20px;
            @media(max-width:900px) { font-size:19px; }
            @media(max-width:600px) { font-size:18px; }
            @media(max-width:450px) { font-size:17px; }
          }
          &:hover,
          &:active,
          &:focus {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.promotion {
  .img-wrap {
    position: relative;
    height:385px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media(max-width:960px) { display:none; }
    img {
      opacity: 0;
    }
    span {
      position: absolute;

    bottom:0;
      left:0;
      padding:200px 25px 20px 25px;
      width:100%;
      background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 14%, #000000 78%);
      font-size: 17px;
      color: #D0B15C;
      line-height: 30px;
      text-transform: uppercase;
    }
  }
  .txt-wrap {
    background-color:#D0B15C;
    padding:15px 30px;
    font-size:14px;
    font-family:$second-font-family;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    color: #434242;
    &:after {
      font-family: FontAwesome;
      content: "\f105";
      color: #484643;
      position: absolute;
      top: 13px;
      right: 17px;
      font-size: 38px;
    }

  }
  a{
    &:hover {
        text-decoration: none;
    }
  }
}

ul.lists {
  margin: 40px 0 0 0 !important;
  li {
    padding:20px 0 !important;
    margin:0 0 0 0 ;
    list-style: none !important;
    border-top:5px #FCF4E0 solid;
    clear: both;
    @media(max-width:800px) { padding:20px 0 !important; }
    .img-wrap {
      width:28%;
      float: left;
      margin: 10px 0 25px 0 !important;
      @media(max-width:650px) {
        width:100%;
        margin-right:0;
        margin-bottom:25px !important;
      }
    }
    .txt-wrap2{
      width: 69%;
      float: right;
      @media(max-width:650px) {
        width:100%;
        margin-right:0;
        margin-bottom:25px !important;
        float: left;
      }
      h2{
        font-size: 20px;
      }
    }
    .txt-wrap-img{
      width: 69%;
      float: right;
      @media(max-width:650px) {
        width:100%;
        margin-right:0;
        margin-bottom:25px !important;
        float: left;
      }
      h2{
        font-size: 20px;
      }
      p, p:first-child {
        font-size: 16px;
        font-weight: normal;
        line-height: 22px;
        margin: 0 0 22px 0;
      }
    }
    h2 {
      font-size:20px;
      color:#333333;
      text-transform: uppercase;
      margin:0 0 10px 0!important;
      padding:0;
      @media(max-width:900px) { font-size:19px; margin:0!important; }
      @media(max-width:600px) { font-size:17px; margin:0!important; }
      @media(max-width:450px) { font-size:15px; margin:0!important; }
    }
    div {
      font-family:$second-font-family;
      font-size: 16px;
      color: #656363;
      letter-spacing: 0;
      line-height:2em;
      margin:0 0 20px;
      padding: 0;
      @media(max-width:900px) { font-size:15px; line-height:2em; margin:0!important;}
      @media(max-width:600px) { font-size:14px; line-height:2em; margin:0!important; }
      @media(max-width:450px) { font-size:13px; line-height:2em; margin:0!important; }
      strong {
        color: #000;
      }
    }
    a.btn {
      font-family:$second-font-family;
      background:#D0B15C;
      color:#131313;
      font-size:17px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 0;
      text-transform: capitalize;
      border-radius:0;
      padding:10px 30px;
    }
  }
}
