.products-selection {
  background:#EEEDED;
  padding:65px 0 90px;
  .inner-wrap {
    ul {
      display:flex;
      margin-left:-25px;
      margin-right:-25px;
      flex-wrap: wrap;
      li {
        margin:25px;
        width:calc(25% - 50px);
        @media(max-width:960px) {
          width:calc(50% - 50px);
        }
        @media(max-width:500px) {
          width:calc(100% - 50px);
        }
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        line-height: 1.35;
        font-family:"proxima-nova";
        background:#fff;
        a {
          color: #333333;
        }
        .img-wrap {
          height:158px;
          overflow: hidden;
          width:100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          img {
            opacity:0;
          }
        }
        .txt-wrap {
          padding:20px 10px;
          text-align: center;
          a {
            margin-top:15px;
            font-size: 13px;
            line-height:1;
            color: #FFFFFF;
            letter-spacing: 0;
            display: inline-block;
            width:100%;
            background:#F4007E;
            max-width:132px;
            padding:13px;
          }
        }
      }
    }
  }
}






.product-view-overview {
  padding:75px 0;
  .inner-wrap {
    display: flex;
    @media(max-width:960px) {
      display: block;
    }
  }
  .gallery-items {
    width:50%;
    @media(max-width:960px) {
      width: 100%;
      display: block;
    }
    vertical-align: top;
    order: 1;
    img {
      max-width:100%;
    }
    .gallery-wrapper {
      height:415px;
      background: #FFFFFF;
      box-shadow: 0 0 3px 0 rgba(0,0,0,0.24);
      width:100%;
      display: block;
      text-align:center;
      margin-bottom:15px;
      .img-wrap {
        display:none;
        width:100%;
        img {
          max-height:400px;
        }
        &:nth-child(1) {
          display:block;
        }
      }
    }
    .thumbs-wrapper {
      position: relative;
      height:132px;
      border-right:1px solid #eee;
      .thumb-wrap {
        display: inline-block;
        padding:2px;
        height:130px;
        line-height: 130px;
        cursor: pointer;
        margin-left:0px;
        margin-right:10px;
        .thumb-outter {
          display: block;
          height:130px;
          line-height: 130px;
          background: #FFFFFF;
          border:1px solid #eee;
          text-align:center;
          overflow: hidden;
          img {
            max-height:130px;
          }
        }
      }
    }
  }
  .txt-wrap {
    width:50%;
    vertical-align: top;
    order: 2;
    padding:10px 0 10px 70px;
    @media(max-width:960px) {
      width: 100%;
      display: block;
      padding:0 0 35px;
    }
    h1 {
      font-size: 32px;
      @media(max-width:900px) { font-size:30px; }
      @media(max-width:600px) { font-size:27px; }
      @media(max-width:450px) { font-size:24px; }
      color: #32323A;
      font-weight:700;
    }
    p {
      margin:0 0 1.5em;
    }
    .tel {
      font-size: 28px;
      color: #787878;
      font-weight: 700;
      margin: 45px 0;
      display:block;
      @media(max-width:900px) { font-size:24px; }
      @media(max-width:600px) { font-size:20px; }
      @media(max-width:450px) { font-size:18px; }
      &:before {
        content: "\f095";
        font-family: FontAwesome;
        font-style: normal;
        font-weight: normal;
        text-decoration: inherit;
        font-size: 34px;
        @media(max-width:900px) { font-size:18px; }
        @media(max-width:600px) { font-size:14px; }
        @media(max-width:450px) { font-size:12px; }
        display: inline-block;
        padding-right: 10px;
        position: relative;
        top: 5px;
      }
    }
  }
}
