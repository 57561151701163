.product-gallery {
  background: #F3F2F2;
  text-align:left;
  padding:50px 0;
  h2 {
    margin:0 0 40px;
    font-size: 26px;
    color: #4A4A4A;
    font-weight:800;
    text-transform: uppercase;
  }
  .gallery-wrap {
    a {
      background: #FFFFFF;
      border: 1px solid #D5D5D5;
      height:190px;
      display:inline-block;
      overflow: hidden;
      margin-left:18px;
      margin-bottom:15px;
      @media(min-width:1305px) {
        width:220px;
        &:nth-child(5n+1), {
          margin-left:0;
        }
      }
      @media(min-width:1000px) and (max-width:1305px) {
        width:calc(25% - 13.5px);
        &:nth-child(4n+1), {
          margin-left:0;
        }
      }
      @media(min-width:700px) and (max-width:1000px) {
        width:calc(33% - 10px);
        &:nth-child(3n+1) {
          margin-left:0;
        }
      }
      @media(min-width:450px) and (max-width:700px) {
        width:calc(50% - 10px);
        &:nth-child(odd) {
          margin-right:20px;
          margin-left:0;
        }
        &:nth-child(even) {
          margin-left:0;
          margin-right:0;
        }
      }
      @media(max-width:450px) {
        width:calc(100%);
        margin-left:0;
      }
      .inner {
        display:flex;
        justify-content:center;
        align-items:center;
        width:100%;
        height:190px;
        img {
          max-height:190px;
        }
      }
    }
  }
}

.loader {
  height:525px;
  display: block;
  width:100%;
  background:#F4F4F4 url('../imgs/productsLoader.gif') center no-repeat;
  z-index: 200;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  @media(max-width:600px) {
    height:350px;
  }
}

.leftBtn-gallery {
  position:absolute;
  top:215px;
  cursor:pointer;
  z-index: 300;
  left:0;
  @media(max-width:600px) {
    top: 98px;
  }
  i {
    font-size: 38px;
    background:$green;
    display: inline-block;
    height: 94px;
    line-height: 94px;
    width: 42px;
    color: #fff;
  }
  &:active {
    top:216px;
    @media(max-width:600px) {
      top: 99px;
    }
  }
}

.rightBtn-gallery {
  @extend .leftBtn-gallery;
  left: auto;
  right:0;
}


.gallery {
  text-align:center;
  overflow:hidden;
  margin:30px auto 0;
  width:100%;
  position:relative;
  background:#F4F4F4;
  border-top:30px #F4F4F4 solid;
  border-bottom:30px #F4F4F4 solid;
  .slideshow-wrap {
    position: absolute;
    top:0;
    left:0;
    width:100%;
    z-index: 100;
    .slideshow-inner-img {
      background-position:center;
      background-repeat:no-repeat;
      background-size:cover;
      cursor: pointer;
      position:relative;
      display:inline-block;
      margin:0;
      background:#013668;
      border-left:15px #F4F4F4 solid;
      border-right:15px #F4F4F4 solid;
      position: relative;
      img {
        opacity:0.50;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        position: relative;
        z-index: 10;
        max-width:none;
        height:525px;
        @media(max-width:600px) {
          height:350px;
        }
      }
      iframe {
        height:525px;
        width:933px;
        @media(max-width:600px) {
          height:350px;
        }
      }
      .caption-wrap {
        position: absolute;
        bottom:0;
        left:0;
        width:100%;
        font-size: 19px;
        color: #FFFFFF;
        line-height: 25px;
        font-weight:600;
        text-transform:uppercase;
        padding:15px 25px;
        background: rgba(20,75,111,0.80);
        z-index: 20;
        text-align:left;
      }
    }
    div.slick-active {
      img { opacity:1; }
    }
  }
}


@media (max-width:400px) {
  .leftBtn { left: 0 !important; top: 44% !important;}
  .rightBtn { right: 0 !important; top: 44% !important;}
}

.gallery-details-item {
  margin:0 auto;
  img {
    padding:5px;
    margin:2.5px 0;
    width:100%;
    max-width:none;
    &:hover {
      opacity:0.7;
    }
  }
  .item {
    overflow: hidden;
  }
  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}


.services-gallery {
  .loader {
    background-color:#fff;
  }
  .gallery {
    margin:0;
    border-top-color:#fff;
    border-bottom-color: #fff;
    .slideshow-wrap {
      .slideshow-inner-img {
        border-left-color:#fff;
        border-right-color:#fff;
      }
    }
  }
}
