.img-frame {
  position: relative;
  display: inline-block;
  img {
    border-left:10px solid #D0BB67;
    border-bottom:10px solid #D0BB67;
  }
  &:after {
    content: " ";
    width:10px;
    height:20px;
    background:#181818;
    position: absolute;
    display:inline-block;
    top:0;
    left:0;
  }
  &:before {
    content: " ";
    width:100px;
    height:10px;
    background:#181818;
    position: absolute;
    display:inline-block;
    bottom:0;
    right:0;
  }
}

.featured-items {
  background-color:#181818;
  background: linear-gradient(30deg, rgba(19,19,19,1) 0%, rgba(19,19,19,1) 50%, rgba(24,24,24,1) 50%, rgba(24,24,24,1) 50%, rgba(24,24,24,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#131313', endColorstr='#181818', GradientType=1 );

  padding:110px 0;
  @media(max-width:900px) { padding:100px 0; }
  @media(max-width:600px) { padding:90px 0; }
  @media(max-width:450px) { padding:80px 0; }
  .inner-wrap {
    max-width:1145px;
    .row:nth-child(2) .txt-wrap {
      ul {
        margin:5px 0;
        li{
          margin: 0 0 1px;
        }
      }
    }
    .row {
      margin-left:0;
      margin-right:0;
      display: flex;
      @media(max-width:900px) {
        display:block;
      }
      .txt-wrap {
        width:50%;
        padding-top:10px;
        padding-bottom:10px;
        font-weight: 400;
        font-family:$second-font-family;
        font-size: 18px;
        line-height: 32px;
        @media(max-width:900px) { width:100%; }
        h3 {
          color:#D3C574;
          font-family:$standard-font-family;
          font-size:23px;
          font-style: normal;
          font-weight: 400;
          text-transform: uppercase;
          margin: 0 0 20px;
          span {
            color:#fff;
          }
        }
        ul {
          list-style: disc;
          color:#fff;
          padding:0;
          margin:20px 0;
          li {
            margin:0 0 10px;
            a{
              text-decoration: none;
              color: #fff;
            }
          }
        }
        p{
          color:#fff;
        }
        strong{
          color:#D3C574;
        }
        em{
          color:#D3C574;
          font-size: 26px;
          text-transform: uppercase;
          font-style: normal;
          padding: 30px 0 0 0;
          float: left;
          font-family:$standard-font-family;

        }
      }
      .img-wrap {
        width:50%;
        position: relative;
        @media(max-width:900px) {
          width:100%;
          text-align:center;
          margin-top:50px;
        }
      }
      &:nth-child(1) {
        margin-bottom:75px;
        .txt-wrap {
          order:2;
          padding:0 50px;
          @media(max-width:900px) { padding:0 40px; }
          @media(max-width:600px) { padding:0 30px; }
          @media(max-width:450px) { padding:0 20px; }
        }
        .img-wrap {
          order:1;
        }
      }
      &:nth-child(2) {
        .txt-wrap {
          div {
            max-width:300px;
            margin:0 auto;
            @media(max-width:900px) { max-width:none; padding:0 50px; }
            @media(max-width:600px) { padding:0 35px; }
            @media(max-width:450px) { padding:0 25px; }
          }
        }
        .img-wrap {
          text-align:right;
          @media(max-width:900px) { text-align:center; }
          .img-frame {
            img {
              border-left:0;
              border-right:10px solid #D0BB67;
            }
            &:before { right:auto; left:0; }
            &:after { right:0; left:auto; }
          }
        }
      }
    }
  }
}

.meet-elaine {
  background:none;
  padding:100px 0 140px;
  position: relative;
  @media(max-width:900px) { padding:80px 0 140px; }
  @media(max-width:600px) { padding:60px 0 110px; }
  @media(max-width:450px) { padding:50px 0 100px; }
  h1 {
    font-size:22px;
    margin-bottom:70px;
    margin-top:40px;
    text-transform: uppercase;
    color:#D3C574;
    @media(max-width:900px) { margin-bottom:50px; font-size:20px; }
    @media(max-width:600px) { margin-bottom:35px; font-size:19px; }
    @media(max-width:450px) { margin-bottom:25px; font-size:18px; }
    span {
      display: block;
      font-size:52px;
      color:#fff;
      line-height: 1em;
      @media(max-width:900px) { font-size:50px; }
      @media(max-width:600px) { font-size:47px; }
      @media(max-width:450px) { font-size:43px; }
    }
  }
  p {
    margin:0 0 1.5em;
  }
  &:before {
    background:#000 url('../imgs/meet-elaine.png') top center no-repeat;
    background-size:cover;
    content: " ";
    height:100%;
    left:0;
    position: absolute;
    top:0;
    width:100%;
    z-index: -100;
  }
  &:after {
    background-image: linear-gradient(-180deg, #131313 0%, rgba(19,19,19,0.56) 44%);
    content: " ";
    height:100%;
    left:0;
    position: absolute;
    top:0;
    width:100%;
    z-index: -50;
  }
  .inner-wrap {

  }
  .meet-elaine-inner {
    padding:40px;
    border-top:rgba(#FFFFFF, 0.14) 1px solid;
    position: relative;
    color:#fff;
    font-family:$second-font-family;
    font-style: normal;
    font-weight: 300;
    font-size:17px;
    line-height: 1.5em;
    @media(max-width:900px) { font-size:16px; line-height: 1.5em; padding:30px; }
    @media(max-width:600px) { font-size:15px; line-height: 1.5em; padding:20px; }
    @media(max-width:450px) { font-size:14px; line-height: 1.5em; padding:10px; }
    &:after {
      background:#D3C574;
      height:8px;
      width:218px;
      position: absolute;
      top:0;
      left:0;
      content: " ";
      display: block;
    }
    .col-txt {
      columns: 2;
      column-gap: 80px;
      @media(max-width:900px) {
        columns: 1;

      }
    }
  }
}
