header {
  width:100%;
  padding:25px 0;
  position: absolute;
  top:0;
  left:0;
  z-index: 1000;
  .page-header-inner {
    display: flex;
    justify-content:space-between;
    align-content:space-between;
  }
  .logo-wrapper2 {
    background:none;
    width:435px;
    position: absolute;
    height:455px;
    left:0;
    top:0;

    a#logo {
      display: block;
      text-align:center;
      width:320px;
      margin:50px 0;
      img {
        width:auto;
      }
    }
    @media(max-width:1500px) {
      display: block;
    }
  }
}

.freetitle {
  text-align: center;
  padding: 150px 0 100px ;
  h1{
    color: #CEBD6B;
    font-size: 28px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 500;
    line-height: 1em;
    margin: 0 0 15px;
  }
  h3{
    color: #171717;
    font-size: 24px;
    text-transform: uppercase;
    margin: 10px 0;
    font-style: normal;
    font-weight: 500;
    line-height: 1.35em;
  }
  p{
    font-family: "proxima-nova";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5em;

  }
}
.freesample {
  background-color: #131313;
  .inner-wrap {
    display: flex;
    justify-content:space-between;
    align-content:space-between;
    @media(max-width:900px) {
      display: block;
      padding-bottom:75px;
    }
    @media(max-width:600px) {
      padding-bottom:50px;
    }
    @media(max-width:450px) {
      padding-bottom:25px;
    }
    .col {
      display:inline-block;
      vertical-align: top;
      @media(max-width:900px) {
        display: block;
        text-align:center;
        padding:75px 0;
      }
      img {
        position: absolute;
        top:-35px;
        @media(max-width:900px) {
          position: static;
        }
      }
      h4 {
        font-family: $title-font-family;
        font-size: 22px;
        color: #181818;
        font-style: normal;
        font-weight: 400;
        line-height: 53px;
        text-transform: uppercase;
        span {
          color:$gold;
        }
        @media(max-width:900px) {
          padding-bottom:20px;
          font-size:38px;
          line-height: 48px;
        }
        @media(max-width:600px) {
          font-size:34px;
          line-height: 32px;
        }
        @media(max-width:450px) {
          font-size:30px;
          line-height: 28px;
        }
      }
      &:nth-child(1) {
        position: relative;
        width:50%;
        @media(max-width:900px) {
          text-align:center;
          width:100%;
        }
        @media(max-width:600px) {
          padding: 50px 0;
        }
        @media(max-width:450px) {
          padding: 25px 0;
        }
      }
      &:nth-child(2) {
        background: #EFEFEF;
        border: 1px solid #E2E2E2;
        padding: 20px;
        margin: 50px 0;
        width: 45%;
        min-height:280px;
        @media(max-width:900px) {
          width:100%;
          margin:0 auto;
          max-width:485px;
          padding-bottom:0;
          min-height: none;
        }
        @media(max-width:900px) {
        }
        input, button{
          margin: 0 0 20px;
        }
      }
    }
  }
}

.freetext {
  padding: 100px 0;

  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5em;


  .col-txt{
    columns: 2;
    column-gap: 80px;
    p {
      margin:0 0 1.5em;
    }
    @media(max-width:900px) {
      columns: 1;
    }
  }
}
