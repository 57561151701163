html {
  box-sizing: border-box;
}

body {
  font:16px/1.5em $standard-font-family;
  position: relative;
  font-style: normal;
  font-weight: 400;
  background-color:#181818;
  @media (max-width:900px) { font-size:15px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:14px; line-height: 1.5em; }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  cursor: pointer;
}

strong {
  font-weight:700;
}


fieldset {
  border:0;
  margin:0;
  padding:0;
}

img {
  max-width:100%;
}

.center { text-align:center; }
.clear { clear:both; }


#page-wrap {
  position: relative;
  overflow: hidden;
}

#page-wrap-inner {
  position: relative;
  right: 0;
  z-index: 100;
  width: 100%;
  transition: right 0.3s ease;
  background: #fff;
  min-height: 100vh;
  a {
    position: relative;
    &:active {
      top:1px;
    }
  }
  &.push {
    right: 100vw;
  }
}

#productFeatureLoader  { display:none; }


#loader {
  display:block;
  margin:35px auto;
}

#zero {
  display:none;
  text-align:center;
  color:#fff;
  padding:35px 0;
}

.default-pointer {
  cursor: default;
}

.hide-from-mobile {
  display: block;
  @media(max-width:900px) {
    display:none;
  }
}

.show-only-on-mobile {
  display: none;
  @media(max-width:900px) {
    clear:both;
    display:inline-block;
  }
}

.loader {
    display: none;
}

.mobile-menu {
  display: none;
  @media(max-width:960px) {
    display:block;
    .select_style {
      margin-bottom:20px;
    }
  }
}

.general {
  font-size: 16px;
  color: #656363;
  letter-spacing: 0;
  line-height: 1.9em;
  font-family:$second-font-family;
  h2 {
    font-size: 28px;
    color: #333333;
    font-style:normal;
    font-weight:500;
    text-transform: uppercase;
  }
  p {
    margin:0 0 1.5em;
    &:first-child {
      font-size: 19px;
      color: #656363;
      letter-spacing: 0;
      line-height: 1.8em;
      @media(max-width:900px) { font-size:17px; line-height: 1.8em; }
      @media(max-width:600px) { font-size:16px; line-height: 1.8em; }
      @media(max-width:450px) { font-size:15px; line-height: 1.8em; }
    }
  }
  ul {
    margin:0 0 1.5em;
    li {
      em {
        font-style: normal;
        text-decoration: underline;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 700;
      }
    }
  }
  img {
    width:100%;
  }
  a img {
    border:none;
  }
  p {
    a {
    background-color:#FF4444 ;
    width: 100%;
    display: block;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-top: 30px;
    outline: 2px solid #FF4444;
    border: 2px solid #fff;
    }
  }
  table{
    width:100%;
    tr:first-child{
      td{
        background-color: #D0B15C;
        width: 50%;
        font-weight: bold;
        color: #1A1A1A;

      }
    }
    tr{
      td{
        width: 50%;
        background-color: #EEEDED;
        padding:10px 20px;
        border-right: 1px solid #fff;
        h4{
          font-size: 14px;
          font-weight: bold;
        }
        h5{
          font-size: 14px;
          font-weight: bold;
          color: #D0B15C;
        }
        li{
          padding: 0px 7px !important;
          font-size: 14px;
        }
        p:first-child, p{
          font-size: 14px;
        }
      }
    }
  }
}

.livinglinks {
  a{
    margin: 0 0 30px 0;
    width: 100%;
    float: left;
  }

}

.adfab{
  h1,h2,h3,h4{
//    color: #ED0081 !important ;
    span{
//      color: #FE75C0 !important;
    }
  }
  p {
    a {
    background-color:#ED0081;
    width: 100%;
    display: block;
    padding: 10px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    margin-top: 30px;
    outline: 2px solid #ED0081;
    border: 2px solid #fff;
    }
  }
}

.abfabcontact {
    margin: 40px 0;
    background: #F2F2F2;
    padding: 20px;
    h2{
      margin-top: 0 !important;
    }
    #contact_btn{
      background: #ED0081;
      color: #fff;
    }
}

//.adfabpromotion{
//  .img-wrap{
//    span{
//      color: #ED0081 !important;
//    }
//  }
//  .txt-wrap {
//    background-color: #ED0081 !important;
//    color: #fff !important;
//  }
//}

.abfabsocial {
  border-top:10px solid #CEB15A !important;
  background: #F2F2F2 !important;
  h3{
    color: #181818!important;
  }
  li a{
    background: #181818 !important;
//    color: #fff !important;
  }
}

.abfabtestimonial{
  margin: 30px 0 ;
  border-top: 10px solid #CEB15A !important;
  background: #F2F2F2 !important;
  padding: 37px;
  h2{
    font-size: 23px;
    color: #181818;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 0 20px;
  }
  .speech-bubble {

    position: relative;
    background: #ffffff;
    border-radius: .4em;
    color: #181818;
    padding: 10px;
    margin: 0 0 10px;
    p{
      font-family: "proxima-nova";
      font-size: 14px;
    }
  }
  .speech-bubble:after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-right-color: #ffffff;
    border-left: 0;
    border-bottom: 0;
    margin-top: -10px;
    margin-left: -20px;
  }
}
