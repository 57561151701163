.contact-wrap {
  h1 {
    text-transform: uppercase;
    color:#181818;
    font-size:22px;
    font-style: normal;
    font-weight: 500;
    margin:0 0 10px;
    padding:0;
    @media(max-width:900px) { font-size:19px; line-height: 1.35em; }
    @media(max-width:600px) { font-size:17px; line-height: 1.35em; }
    @media(max-width:450px) { font-size:15px; line-height: 1.35em; }
  }
  h2 {
    color:#CDBD69;
    text-transform: uppercase;
    font-size:39px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.35em;
    margin:0 0 45px;
    @media(max-width:900px) { font-size:33px; line-height: 1.35em; }
    @media(max-width:600px) { font-size:29px; line-height: 1.35em; }
    @media(max-width:450px) { font-size:26px; line-height: 1.35em; }
  }
  .row {
    margin-left:0;
    margin-right:0;
    display:block;
    .col {
      &:nth-child(1) {
        display:inline-block;
        width:calc(100% - 525px);
        vertical-align: top;
        @media(max-width:960px) {
          display:block;
          width:100%;
        }
        form {
          label {
            display: none;
          }
          input, textarea {
            background: rgba(216,216,216,0.14);
            border: 1px solid #E7E2E2;
            margin:0 0 20px;
          }
          button {
            display: inline-block;
            width:auto;
            background: #D0B15C;
            font-family:$second-font-family;
            font-size:17px;
            font-style: normal;
            font-weight: 600;
            text-transform: capitalize;
            padding:10px 50px;
          }
        }
      }
      &:nth-child(2) {
        display:inline-block;
        width:445px;
        vertical-align: top;
        margin-left:80px;
        background:#EEEDED;
        padding:35px 35px 45px;
        border-top:10px solid #CEB15A;
        @media(max-width:960px) {
          display:block;
          width:100%;
          margin-top:50px;
          margin-left:0;
        }
        @media(max-width:900px) { padding:30px 30px 40px; }
        @media(max-width:600px) { padding:25px 25px 35px; }
        @media(max-width:450px) { padding:20px 20px 30px; }
        h3 {
          font-size: 15px;
          color: #181818;
          font-style: normal;
          font-weight: 500;
          text-transform: uppercase;
          font-family:$title-font-family;
          @media(max-width:900px) { font-size:14px; }
          @media(max-width:600px) { font-size:13px; }
          @media(max-width:450px) { font-size:12px; }
        }
        h2 {
          font-size: 43px;
          color: #D0B15C;
          font-style: normal;
          font-weight: 500;
          font-family:$title-font-family;
          margin:0 0 30px;
          @media(max-width:900px) { font-size:40px; }
          @media(max-width:600px) { font-size:36px; }
          @media(max-width:450px) { font-size:32px; }
        }
        div {
          display:flex;
          flex-wrap:wrap;
          align-items:stretch;
          align-content:space-between;
          margin-top:15px;
          @media(max-width:900px) { margin-top:12px; }
          @media(max-width:600px) { margin-top:9px; }
          @media(max-width:450px) { margin-top:6px; }
          a {
            width:calc(50% - 5px);
            background: #1A1A1A;
            color:#E7D5A2;
            font-family:$second-font-family;
            font-style: normal;
            font-weight: 500;
            font-size:17px;
            padding:10px 18px;
            display:flex;
            align-items:center;
            align-content:center;
            margin-top:5px;
            margin-bottom:5px;
            &:nth-child(2n+1) {
              margin-right:10px;
            }
            &:hover,
            &:active,
            &:focus {
              text-decoration: none;
            }
            span {
              flex:1;
              padding:0;
            }
            i {
              color:#E7D5A2;
              font-size:30px;
              width:30px;
              text-align:center;
            }
            @media(max-width:500px) {
              width:100%;
              &:nth-child(2n+1) {
                margin-right:0;
              }
            }
          }
        }
      }
    }
  }
}
