.about-page {
  .inner-wrap {
    max-width:1080px;
    display: flex;
    align-items: stretch;
    @media(max-width:900px) {
      display:block;
    }
    .img-wrapper-frame {
      border-right:10px solid #D0BB67;
      border-bottom:10px solid #D0BB67;
      position: relative;
      &:before {
        content: " ";
        display: block;
        height:50%;
        position: absolute;
        right:-10px;
        top:0;
        background:#fff;
        width:10px;
      }
      &:after {
        content: " ";
        display: block;
        width:50%;
        position: absolute;
        left:0;
        bottom:-10px;
        background:#fff;
        height:10px;
      }
    }
    .col {
      width:50%;
      @media(max-width:900px) {
        width:100%;
      }
      h1 {
        font-family: $title-font-family;
        font-size: 42px;
        color: #171717;
        text-transform: uppercase;
        padding:0;
        span {
          color:$gold;
        }
        @media(max-width:900px) { font-size:37px; padding:0; }
        @media(max-width:600px) { font-size:34px; padding:0; }
        @media(max-width:450px) { font-size:30px; padding:0; }
      }
      blockquote {
        border-left: 3px solid #CDBD69;
        padding: 15px 30px 15px 30px;
        color: #393838;
        margin: 0 0 1.5em;
        font-weight: 600;
        margin: 30px 0;
        background: #FFF8E6;
        font-size:16px;
        font-family:$second-font-family;
      }
      div {
        p {
          margin:0 0 1.5em;
          color:#656363;
          font-size:16px;
          font-family:$second-font-family;
          @media(max-width:900px) { font-size:15px; }
          @media(max-width:600px) { font-size:14px; }
          @media(max-width:450px) { font-size:13px; }
          &:nth-child(1) {
            font-size:19px;
            margin: 0 0 1.5em;
            line-height: 1.5em;
            @media(max-width:900px) { font-size:18px; line-height: 1.5em; }
            @media(max-width:600px) { font-size:17px; line-height: 1.5em; }
            @media(max-width:450px) { font-size:16px; line-height: 1.5em; }
          }
        }
      }
      &:nth-child(1) {

      }
      &:nth-child(2) {
        padding-left:60px;
        @media(max-width:900px) {
          padding-left:0;
          text-align:center;
          margin-top:85px;
        }
        .img-wrap {
          display:block;
          height:100%;
          .img-wrapper-frame {
            img {
              max-width:100%;
              display: block;
              @media(max-width:900px) {
                width:100%;
              }
            }
          }
        }
      }
    }
  }
}

.more-details-wrap {
  text-align:center;
  background:#EEEDED;
  padding:75px 0;
  .row {
    margin:0;
    h3 {
      color:#171717;
      font-size:24px;
      text-transform: uppercase;
      margin:10px 0;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35em;
      @media(max-width:900px) { font-size:22px; line-height: 1.35em; }
      @media(max-width:600px) { font-size:20px; line-height: 1.35em; }
      @media(max-width:450px) { font-size:18px; line-height: 1.35em; }
    }
    h2 {
      color:#CEBD6B;
      font-size:32px;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      line-height: 1.35em;
      @media(max-width:900px) { font-size:28px; line-height: 1.35em; }
      @media(max-width:600px) { font-size:24px; line-height: 1.35em; }
      @media(max-width:450px) { font-size:20px; line-height: 1.35em; }
    }
  }
  .grid-wrap {
    display: flex;
    margin:45px 0;
    @media(max-width:900px) {
      flex-direction: column;
      margin-top:0;
    }
  }
  .col {
    width:50%;
    text-align:left;
    color:#656363;
    font-size:16px;
    font-family:$second-font-family;
    line-height: 2em;
    font-style: normal;
    font-weight: 400;
    p {
      margin:0 0 1.5em;
      &:nth-child(1) {
        font-size:19px;
        line-height: 2em;
      }
    }
    &:nth-child(1) {
      padding-right:30px;
      @media(max-width:900px) {
        padding-right:0;
        width:100%;
        order: 2;
        text-align:center;
        padding-top:50px;
      }
    }
    &:nth-child(2) {
      padding-left:40px;
      @media(max-width:900px) {
        padding-left:0;
        width:100%;
      }
    }
    .img-wrap {
      margin-top:45px;
      &:nth-child(1) {
        margin-top:0;
      }
      .img-wrapper-frame {
        border-left:10px #D0BB67 solid;
        border-bottom:10px #D0BB67 solid;
        display: inline-block;
        position: relative;
        img { }
        &:before {
          background:#EEEDED;
          content: " ";
          height:20px;
          left:-10px;
          position: absolute;
          top:0;
          width:10px;
          z-index: 100;
        }
        &:after {
          background:#EEEDED;
          content: " ";
          width:20px;
          right:0;
          position: absolute;
          bottom:-10px;
          height:10px;
          z-index: 100;
        }
      }
    }
  }
}
