.slideshow {
  position: relative;
  z-index: 50;
  width:100%;
  .slide {
    height:455px;
    max-height:100vh;
    width:100%;
    position: relative;
    background-repeat:no-repeat;
    background-size:cover;
    background-position: right top;
    @media(max-width:600px) {
      height:260px;
    }
    .fade {
      background: linear-gradient(to right, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 60%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#000000', GradientType=1 );      height: 100%;
      width: 100%;
      max-width:100%;
      margin: auto;
    }
    .inner-wrap {
    }

    .logo-within-slide {
      display: none;
      @media(max-width:1500px) {
        position: absolute;
        left:0;
        top:0;
        display: block;
        background:#000;
        height:455px;
        width:405px;
        background: rgba(0,0,0,1);
        //background: -moz-linear-gradient(-45deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        //background: -webkit-gradient(left top, right bottom, color-stop(50%, rgba(0,0,0,1)), color-stop(50%, rgba(0,0,0,0)), color-stop(100%, rgba(0,0,0,0)));
        //background: -webkit-linear-gradient(-45deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        //background: -o-linear-gradient(-45deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        //background: -ms-linear-gradient(-45deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        //background: linear-gradient(131deg, rgba(0,0,0,1) 50%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 100%);
        //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000', endColorstr='#000', GradientType=1 );
        background: linear-gradient(131deg, rgba(0,0,0,0.4) 0%, rgba(2,2,2,0.4) 50%, rgba(3,3,3,0) 50%, rgba(5,5,5,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#050505', GradientType=1 );
        @media(max-width:900px) {
          width:320px;
          height:393px;
        }
        @media(max-width:800px) {
          width:260px;
          height:320px;
        }
        @media(max-width:700px) {
          background: none;
        }
        a {
          display: block;
          text-align:center;
          width:320px;
          margin:50px 0;
          @media(max-width:1300px) {
            margin:40px;
            width:auto;
            text-align:left;
            display: block;
          }
          @media(max-width:800px) {
            margin:20px;
          }
          img {
            width:auto;
            @media(max-width:420px) {
              width:70px;
            }
          }
        }
      }
    }
    .txt-wrap {
      position: relative;
      z-index: 1000;
      padding-top:210px;
      max-width:760px;
      pointer-events: none;
      @media(max-width:600px) {
        padding-top: 130px;
      }
      h2 {
        color:#CDBD69;
        font-size:22px;
        text-transform: uppercase;
        margin:0 0 5px;
        font-style: normal;
        font-weight: 500;
        @media(max-width:900px) { font-size:19px; line-height: 1.2em; }
        @media(max-width:600px) { font-size:17px; line-height: 1.2em; }
        @media(max-width:450px) { font-size:16px; line-height: 1.2em; }
      }
      p {
        color:#FFFFFF;
        font-size:38px;
        text-transform: uppercase;
        margin:0;
        font-style: normal;
        font-weight: 500;
        line-height: 1.2em;
        @media(max-width:900px) { font-size:37px; line-height: 1.2em; }
        @media(max-width:600px) { font-size:32px; line-height: 1.2em; }
        @media(max-width:450px) { font-size:20px; line-height: 1.2em; }
      }
    }
    span.btm {
      position: absolute;
      bottom:65px;
      left:0;
      width:100%;
      height:32px;
      @media(max-width:600px) { display: none;}
      span {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCAyMCAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIG9wYWNpdHk9IjAuNzE5NzQxMTM4Ij4gICAgICAgIDxnIGlkPSJEZXNrdG9wLUhELUNvcHktMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTkzLjAwMDAwMCwgLTY5OC4wMDAwMDApIj4gICAgICAgICAgICA8ZyBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDkzLjAwMDAwMCwgNjk4LjAwMDAwMCkiPiAgICAgICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlLTQiIHN0cm9rZT0iIzIzMjMyMyIgc3Ryb2tlLXdpZHRoPSIyIiB4PSIxIiB5PSIxIiB3aWR0aD0iMTgiIGhlaWdodD0iMzAiIHJ4PSI5Ij48L3JlY3Q+ICAgICAgICAgICAgICAgIDxjaXJjbGUgaWQ9Ik92YWwiIGZpbGw9IiMyMzIzMjMiIGN4PSIxMCIgY3k9IjgiIHI9IjIiPjwvY2lyY2xlPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
        width:20px;
        height:32px;
        line-height: 32px;
        display: inline-block;
      }
    }
  }
  &:after {
    position: absolute;
    bottom:-44px;
    left:calc(50% - 38px);
    background:url('../imgs/mini_logo.png') no-repeat;
    content: " ";
    height:66px;
    width:76px;
    z-index: 5000;
  }
  &.homepage-slideshow {
    .slide {
      height: 0;
      padding-top: 55%;
      .txt-wrap {
        padding-top:295px;
        @media(max-width:900px) { padding-top:225px; }
        @media(max-width:600px) { padding-top:130px; }
        h2 {
          font-size: 52px;
          color: #1F1F1F;
          font-style: normal;
          font-weight: 500;
          max-width:760px;
          text-transform: capitalize;
          line-height: 1.35em;
          @media(max-width:900px) { font-size:44px; line-height: 1.35em; }
          @media(max-width:600px) { font-size:36px; line-height: 1.35em; }
          @media(max-width:450px) { font-size:30px; line-height: 1.35em; }
        }
        p {
          margin:0 auto;
          font-size:24px;
          color:#977940;
          max-width:883px;
          font-weight: 500;text-transform: capitalize;
        }
        a.btn {
          font-family:$second-font-family;
          font-size:14px;
          color:#2E2D2D;
          font-weight:600;
          margin-top:58px;
          @media(max-width:600px) { display: none;}
          &:after {
            font-family:Arial;
            font-size:16px;
            color:#80692B;
            content: "\2192";
            display: inline-block;
            margin-left:20px;
            position: relative;
            top:-1px;
          }
        }
      }
      .logo-within-slide {
        display: none;
        @media(max-width:1500px) {
          display: block;
          background:#fff;
          height:500px;
          width:435px;
          position: absolute;
          pointer-events: none;
          left:0;
          top:0;
          background: rgba(255,255,255,1);
          //background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
          //background: -webkit-gradient(left top, right bottom, color-stop(50%, rgba(255,255,255,1)), color-stop(50%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,0)));
          //background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
          //background: -o-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
        //  background: -ms-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
          background: linear-gradient(131deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
          z-index: 500;
          @media(max-width:1300px) {
            height:380px;
            width:330px;
          }
          @media(max-width:800px) {
            height:315px;
            width:270px;
          }
          @media(max-width:700px) {
            background: none;
          }
        }
      }
    }
  }
}
