#mobile-nav-items {
    bottom: 0;
    display: block;
    height: 100vh;
    position: fixed;
    right: 0;
    text-align:left;
    top: 0;
    width: 100vw;
    z-index: 50;
    background: #181818;
    .menu-wrap{
        display:flex;
        align-items:center;
        height:100vh;
        width:100%;
        padding:100px;
        @media(max-width:600px) { padding:90px; }
        @media(max-width:550px) { padding:80px; }
        @media(max-width:500px) { padding:70px; }
        @media(max-width:450px) { padding:60px; }
        .row {
          display: block;
          margin:0 auto;
          width:100%;
          max-width:1105px;
          .col {
            display: inline-block;
            vertical-align: top;
            &:nth-child(1) {
              width:33.33333%;
              @media(max-width:1100px) {
                width:100%;
                margin-bottom:30px;
              }
            }
            &:nth-child(2),
            &:nth-child(3) {
              width:33.33333%;
              @media(max-width:1100px) {
                width:50%;
              }
              @media(max-width:800px) {
                width:100%;
                display: block;
              }
            }
             ul {
               li {
                 color:$gold;
                 font-size:29px;
                 font-weight:400;
                 font-style:normal;
                 display: block;
                 margin:15px 0;
                 @media(max-width:600px) { font-size:27px; margin:12px 0; }
                 @media(max-width:550px) { font-size:24px; margin:10px 0; }
                 @media(max-width:500px) { font-size:20px; margin:8px 0; }
                 @media(max-width:450px) { font-size:18px; margin:6px 0; }
                 a {
                   color:$gold;
                 }
               }
               .active {
                 color: #fff !important;
                 a{
                   color: #fff !important;
                 }
               }
             }
          }
        }
    }
    .social-items {
      display:block;
      width:100%;
      margin-top:50px;
      a {
        width:auto;
        height:50px;
        width:50px;
        line-height: 45px;
        border:3px $gold solid;
        color:$gold;
        margin-right:10px;
        border-radius:50%;
        text-align:center;
        display: inline-block;
        @media(max-width:600px) { height:45px; width:45px; line-height: 40px; }
        @media(max-width:550px) { height:40px; width:40px; line-height: 30px; border:2px $gold solid; }
        @media(max-width:500px) { height:35px; width:35px; line-height: 25px; }
        @media(max-width:450px) { height:30px; width:30px; line-height: 20px; }
        i {
          margin:0;
          font-size:28px;
          @media(max-width:600px) { font-size:24px;  }
          @media(max-width:550px) { font-size:20px;  }
          @media(max-width:500px) { font-size:18px;  }
          @media(max-width:450px) { font-size:17px; }
        }
      }
      &.desktop-only {
        display: block;
        @media(max-width:1100px) {
          display: none;
        }
      }
      &.mobile-only {
        display: none;
        @media(max-width:1100px) {
          display: block;
        }
      }
    }
}
