header {
  width:100%;
  padding:25px 0;
  position: absolute;
  top:0;
  left:0;
  z-index: 1000;
  .page-header-inner {
    display: flex;
    justify-content:space-between;
    align-content:space-between;
  }
  .logo-wrapper {
    background:#fff;
    width:435px;
    position: absolute;
    height:455px;
    left:0;
    top:0;
    background: linear-gradient(131deg, rgba(0,0,0,0.4) 0%, rgba(2,2,2,0.4) 50%, rgba(3,3,3,0) 50%, rgba(5,5,5,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#050505', GradientType=1 );
    a#logo {
      display: block;
      text-align:center;
      width:320px;
      margin:50px 0;
      img {
        width:auto;
      }
    }
    @media(max-width:1500px) {
      display: none;
    }
    @media(max-width:700px) {
      background: none;
    }
  }
}

.homepage {
  .logo-wrapper {
    background:#fff;
    background: rgba(255,255,255,1);
    background: -moz-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(50%, rgba(255,255,255,1)), color-stop(50%, rgba(255,255,255,0)), color-stop(100%, rgba(255,255,255,0)));
    background: -webkit-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: -o-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: -ms-linear-gradient(-45deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    background: linear-gradient(131deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%, rgba(255,255,255,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );
    height:500px;
    @media(max-width:700px) {
      background: none;
    }
  }
}

/* Navigation Button */
#mobile-burger-btn {
  cursor: pointer;
  height: 40px;
  text-align:center;
  transition: right 0.5s;
  z-index: 100;
  color:#373737;
  position:fixed;
  top:25px;
  right:25px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI4MHB4IiBoZWlnaHQ9IjMycHgiIHZpZXdCb3g9IjAgMCA4MCAzMiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5Hcm91cCA0PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxkZWZzPjwvZGVmcz4gICAgPGcgaWQ9IlBhZ2UtMSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iRGVza3RvcC1IRC1Db3B5LTIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjM5LjAwMDAwMCwgLTUyLjAwMDAwMCkiPiAgICAgICAgICAgIDxnIGlkPSJHcm91cC00IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMjM5LjAwMDAwMCwgNTIuMDAwMDAwKSI+ICAgICAgICAgICAgICAgIDxnIGlkPSJHcm91cC02Ij4gICAgICAgICAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUtMTEiIGZpbGw9IiMzMDMwMzAiIHg9IjAiIHk9IjAiIHdpZHRoPSI3NCIgaGVpZ2h0PSIzMiIgcng9IjUiPjwvcmVjdD4gICAgICAgICAgICAgICAgICAgIDx0ZXh0IGlkPSJNRU5VIiBmb250LWZhbWlseT0iUHJveGltYU5vdmEtU2VtaWJvbGQsIFByb3hpbWEgTm92YSIgZm9udC1zaXplPSIxNCIgZm9udC13ZWlnaHQ9IjUwMCIgbGluZS1zcGFjaW5nPSIzMCIgZmlsbD0iI0UwQzVBNCI+ICAgICAgICAgICAgICAgICAgICAgICAgPHRzcGFuIHg9IjE3LjA0MyIgeT0iMjAiPk1FTlU8L3RzcGFuPiAgICAgICAgICAgICAgICAgICAgPC90ZXh0PiAgICAgICAgICAgICAgICAgICAgPHJlY3QgaWQ9IlJlY3RhbmdsZS0xMiIgZmlsbD0iIzJBMkEyQSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNzEuMDAwMDAwLCAxNi4wMDAwMDApIHJvdGF0ZSgtMzE1LjAwMDAwMCkgdHJhbnNsYXRlKC03MS4wMDAwMDAsIC0xNi4wMDAwMDApICIgeD0iNjUiIHk9IjEwIiB3aWR0aD0iMTIiIGhlaWdodD0iMTIiPjwvcmVjdD4gICAgICAgICAgICAgICAgPC9nPiAgICAgICAgICAgIDwvZz4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg==);
  background-repeat:no-repeat;
  background-position:left top;
  padding-left:90px;
  mix-blend-mode:lighten;
  @media(max-width:700px) {
    background-image: none;
  }
  @media(max-width:700px) {
    height: 40px;
    width: 40px;
    padding: 7px;
    color: #fff;
    background: #cdb060;
    border-radius: 50px;
  }

  i {
    font-size:32px;
    position: relative;
    mix-blend-mode:lighten;
    @media(max-width:700px) {
      font-size:25px;
    }
    &:nth-child(2) {
      display:none;
    }
  }
  &.menu-open {
    //right:270px;
    background-image:none;
    i {
      color:#fff;
      &:nth-child(1) { display: none; }
      &:nth-child(2) { display: inline-block; }
    }
  }
}
