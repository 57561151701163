
$title-font-family:	"ratiomodern";
$second-font-family: "proxima-nova";
$standard-font-family: "ratiomodern";

$container: 1160;

$gold: #AF9963;

$orange: #F26631;
$navy: #32323A;

$red: #FD0000;
$green: #83AE00;
$grey: rgba(#9B9B9B,0.11);

$blue: #144B6F;
$dark-blue: #124363;
$deep-blue: #103D5A;

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
