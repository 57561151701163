
h1, h2, h3 {
  font-family:$title-font-family;
  font-style: normal;
  font-weight: 500;
}

h1, h2 {
  font-size:42px;
  line-height:1.2em;
  margin:0 0 30px;
  @media (max-width:900px) { font-size:36px; line-height: 1.5em; }
  @media (max-width:600px) { font-size:32px; line-height: 1.5em; }
  @media (max-width:450px) { font-size:30px; line-height: 1.5em; }
}
